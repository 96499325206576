import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles(() => {
  return {
    wrapper: {
      '& > div > div > div': {
        borderTopColor: colors.primary,
        '&:before': {
          borderTopColor: colors.primary,
        },
        '&:after': {
          borderTopColor: colors.primary,
        },
      },
    },
  };
});
