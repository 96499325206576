import { Loader as ImpactReactLoader, LoaderProps } from 'impact-react-components';

import { useStyles } from './styles';

export const Loader = ({ ...props }: LoaderProps) => {
  const classes = useStyles();

  return (
    <span className={classes.wrapper}>
      <ImpactReactLoader {...props} />
    </span>
  );
};
