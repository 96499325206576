export const AVAILABLE_COMODITY_PRICES_OIL = [
  {
    value: 'Manual',
    key: 'MANUAL',
  },
  {
    value: 'Bakken Williston',
    key: 'AAXPP00',
  },
  {
    value: 'Cold Lake',
    key: 'AASZX00',
  },
  {
    value: 'Eagle Ford Crude',
    key: 'AAYAT00',
  },
  {
    value: 'Houston Light Sweet',
    key: 'AAXEW00',
  },
  {
    value: 'Kern River/San Joaquin Valley',
    key: 'PCAAD00',
  },
  {
    value: 'Line 63',
    key: 'PCAAD00',
  },
  {
    value: 'Lloyd Blend',
    key: 'AALRK00',
  },
  {
    value: 'Syncrude Sweet Premium',
    key: 'AASOK00',
  },
  {
    value: 'Thums',
    key: 'PCAAD00',
  },
  {
    value: 'Thunderhorse',
    key: 'AAWZK00',
  },
  {
    value: 'WTI Frontline at Houston MOC',
    key: 'CN05G',
  },
  {
    value: 'WTI Midland',
    key: 'CN0RO',
  },
  {
    value: 'Wyoming Sweet',
    key: 'PCACM00',
  },
];

export const AVAILABLE_COMODITY_PRICES_GAS = [
  {
    value: 'Manual',
    key: 'MANUAL',
  },
  {
    value: 'ANR OK',
    key: 'CN0S0',
  },
  {
    value: 'CHEYENNE',
    key: 'CN089',
  },
  {
    value: 'CIG Rocky Mtns',
    key: 'CN08A',
  },
  {
    value: 'Eastern Gas South',
    key: 'CN06P',
  },
  {
    value: 'El Paso SanJuan',
    key: 'CN08D',
  },
  {
    value: 'FL Gas Zn3',
    key: 'CN07G',
  },
  {
    value: 'Henry Hub',
    key: 'CN07I',
  },
  {
    value: 'Mich Con CG',
    key: 'CN093',
  },
  {
    value: 'NGPL Midcont',
    key: 'CN0SQ',
  },
  {
    value: 'NGPL Texok Zn',
    key: 'CN07M',
  },
  {
    value: 'NW WY Pool/Rky',
    key: 'CN08K',
  },
  {
    value: 'Nrthrn Ventura',
    key: 'CN095',
  },
  {
    value: 'Oneok OK',
    key: 'CN0T1',
  },
  {
    value: 'PG&E CG',
    key: 'CN08L',
  },
  {
    value: 'SoCal Gas citygate',
    key: 'CN08Q',
  },
  {
    value: 'TC Alb AECO-C',
    key: 'CN08T',
  },
  {
    value: 'TX Eastern S TX',
    key: 'CN087',
  },
  {
    value: 'Transco Leidy Line receipts',
    key: 'CN073',
  },
  {
    value: 'Transco Zn4',
    key: 'CN07Z',
  },
  {
    value: 'Waha',
    key: 'CN08V',
  },
  {
    value: 'White River Hub',
    key: 'CN08W',
  },
];

export const MANUAL = 'Manual';
export enum EManualPriceType {
  MANUAL_GAS = 'manualGas',
  MANUAL_OIL = 'manualOil',
}
export const BOLD_OPTION_LIST_ECONOMICS = [MANUAL];
export const MIN_LABEL = '1';
export const MAX_LABEL = '100';
export const MIN_VALUE = 1;
export const MAX_VALUE = 100;

export const STANDARD_STEP = 1;
export const FRACTIONAL_STEP = 0.01;
export const MAX_LENGTH_FRACTIONAL_INPUT = 4;
export const MAX_LENGTH_DEFAULT_INPUT = 12;
export const DEFAULT_MAXIMUM_DECIMAL_PLACES = 2;
