import { TestLocators } from 'constants/Locators';
import { RequestStatus } from 'constants/RequestStatus';

import { Box, Button, ButtonGroup, Typography } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Loader } from 'app/components/common/Loader';
import { actions as privateRouteActions } from 'app/containers/PrivateRoute/slice';
import { ProductUpdateType } from 'types/ProductUpdate';

import { LOADER_SIZE, NOTIFICATIONS_LIST_LENGTH } from './constants';
import { NotificationsList } from './NotificationsList';
import { useStyles } from './styles';
import { ParsedProductUpdate } from './types';

interface NotificationBodyProps {
  status: RequestStatus;
  notifications: ParsedProductUpdate[];
  showMarkAllAsRead: boolean;
  handleAllAsRead: () => void;
  handleSelectNotificationsCategory: (category: ProductUpdateType) => void;
}

export function NotificationBody({
  status,
  notifications,
  showMarkAllAsRead,
  handleAllAsRead,
  handleSelectNotificationsCategory,
}: NotificationBodyProps) {
  const dispatch = useDispatch();
  const [notificationsData, setNotificationsData] = useState<ParsedProductUpdate[]>();
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    dispatch(privateRouteActions.updateUserVisitedDates());
  }, [dispatch]);

  useEffect(() => {
    setNotificationsData(notifications);
  }, [notifications]);

  const filteredNotifications = useMemo(
    () => notificationsData?.filter(({ productupdatetype }) => productupdatetype === ProductUpdateType.Notification),
    [notificationsData],
  );

  const renderNotificationsList = useMemo(
    () => (
      <NotificationsList
        notifications={filteredNotifications?.slice(0, NOTIFICATIONS_LIST_LENGTH)}
        handleSelectNotificationsCategory={handleSelectNotificationsCategory}
      />
    ),
    [filteredNotifications, handleSelectNotificationsCategory],
  );

  return (
    <>
      <div className={classes.menuItem}>
        <div className={classes.header}>
          <Typography variant="body1" className={classes.label}>
            {t('LANDING_PAGE.NOTIFICATIONS.TITLE')}
          </Typography>
          {showMarkAllAsRead && (
            <Typography
              variant="h5"
              onClick={handleAllAsRead}
              className={classes.markAll}
              data-testid={TestLocators.MarkAllRead}
            >
              {t('LANDING_PAGE.NOTIFICATIONS.MARK_ALL')}
            </Typography>
          )}
        </div>
        <Box className={classes.animatedBorder} />
      </div>
      <div data-testid={TestLocators.Landing_Notifications_Content} className={classes.container}>
        {status === RequestStatus.LOADING && (
          <div className={classes.loader}>
            <Loader size={LOADER_SIZE} />
          </div>
        )}
        {renderNotificationsList}
      </div>
      {filteredNotifications && (
        <div className={classes.viewAllContainer}>
          <button
            data-testid={TestLocators.Notifications_AllNotifications}
            onClick={() => handleSelectNotificationsCategory(ProductUpdateType.Notification)}
            className={classes.viewAllLink}
          >
            {t('LANDING_PAGE.NOTIFICATIONS.VIEW_ALL')}
          </button>
        </div>
      )}
      <div className={classes.notificationGroupsContainer}>
        <ButtonGroup className={classes.notificationGroups}>
          <Button
            data-testid={TestLocators.Notifications_DataUpdates}
            className={classes.groupButton}
            onClick={() => handleSelectNotificationsCategory(ProductUpdateType.DataUpdate)}
          >
            {t('LANDING_PAGE.DATA_UPDATES')}
          </Button>
          <Button
            data-testid={TestLocators.Notifications_PlatformUpdates}
            className={classes.groupButton}
            onClick={() => handleSelectNotificationsCategory(ProductUpdateType.ProductUpdate)}
          >
            {t('LANDING_PAGE.PLATFORM_UPDATES')}
          </Button>
        </ButtonGroup>
      </div>
    </>
  );
}
