export enum EDatabaseName {
  OLD_HEAVYAI = 'heavyai',
  Legacy_Database = 'Legacy_Database',
  CSM = 'CSM_Database',
  INFORMATION_SCHEMA = 'information_schema',
}

export const DOCUMENTATION = 'documentation';

export const WIDGET_HEIGHT = 498;

export enum EPlanWidgets {
  MAP = 'plan_map',
  PRODUCTION_CURVES = 'production_curves',
  LATERAL_LENGTH = 'lateral_length',
  PROPANT_PER_FT = 'propant_per_ft',
  FLUID_PER_PROPANT_RATIO = 'fluid_per_propant_ratio',
  NEAREST_SAME_RESERVOIR_NEIGHBOR_SPACING = 'nearest_same_reservoir_neighbor_spacing',
}

export enum EEconomicsWidgets {
  MAP = 'economics_map',
  MONTHLY_PRODUCTION_OIL_AND_GAS = 'monthly_production_oil_and_gas',
  MONTHLY_CASHFLOW_AND_CUMULATIVE_CASHFLOW = 'monthly_cashflow_and_cumulative_cashflow',
  GAS_AND_OIL_PRICE = 'gas_and_oil_price',
}

export enum EPredictWidgets {
  MAP = 'predict_map',
  PRODUCTION_CURVES = 'predict_production_curves',
}

export const MAP_WIDGETS: string[] = [EPlanWidgets.MAP, EPredictWidgets.MAP];
export const RIGHT_SIDE_WIDGETS: string[] = [
  EPredictWidgets.PRODUCTION_CURVES,
  EPlanWidgets.PRODUCTION_CURVES,
  EPlanWidgets.PROPANT_PER_FT,
  EPlanWidgets.NEAREST_SAME_RESERVOIR_NEIGHBOR_SPACING,
  EEconomicsWidgets.MONTHLY_CASHFLOW_AND_CUMULATIVE_CASHFLOW,
];
