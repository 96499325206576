import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme) => {
  const { type } = theme.palette;
  const isDarkTheme = type === ThemeMode.DARK;

  return createStyles({
    profile: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      cursor: 'pointer',
      paddingLeft: 5,
      marginLeft: 5,
      marginRight: 5,
      color: isDarkTheme ? colors.baseGrey10 : colors.grey42,
      ...(isDarkTheme ? {} : { '&:hover': { color: colors.primary } }),
    },
    profileBackground: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 'initial',
      margin: 0,
      justifyContent: 'center',
      height: '36px',
      width: '36px',
      backgroundColor: isDarkTheme ? colors.baseGrey57 : colors.primaryWhite,
    },
    profileIcon: {
      width: 32,
      height: 32,
      padding: 3,
      border: `1px ${colors.grey62} solid`,
      borderRadius: '5px',
    },
    arrowIcon: {
      fontSize: 16,
      marginLeft: -2,
      marginRight: -3,
      verticalAlign: 'middle',
    },
    '@keyframes showHighlightBar': {
      '0%': { transform: 'scale(0, 1)' },
      '100%': { transform: 'scale(1, 1)' },
    },
  });
});
